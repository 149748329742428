import React from 'react';
import './SwitchButton.css';

const SwitchButton = ({ isActive, setIsActive }) => {

    const toggleSwitch = () => {
        setIsActive(!isActive)
    };

    return (
        <label className="switch">
            <input
                type="checkbox"
                checked={isActive}
                onChange={toggleSwitch}
            />
            <span className="slider"></span>
        </label>
    );
};

export default SwitchButton;
