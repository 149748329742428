import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";

import {axiosSendPromocode} from '@API/promocode';
import i18n from '@Utils/i18n/i18n';

const usePromoCode = (promocode, parkingUid, plateNumber) => {
    const [promoCode, setPromoCode] = useState(promocode);
    const navigate = useNavigate();

    const transformPromocode = (code) => {
        const dashIndex = code.indexOf("-");
        if (dashIndex === -1) return code.toUpperCase();

        const left = code.substring(0, dashIndex + 1);
        const right = code.substring(dashIndex + 1).toUpperCase();
        return left + right;
    };

    const toastConfig = {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    };

    const checkPromoCode = async () => {
        try {
            const transformedPromocode = transformPromocode(promoCode);
            const res = await axiosSendPromocode(transformedPromocode, plateNumber);

            if (res?.status === 200) {
                toast.success(res?.data?.message, toastConfig);
                setTimeout(() => navigate(`/payment-success/${parkingUid}/${plateNumber}`), 3000);
            } else if (res?.status === 208) {
                toast.error(res?.data?.message, toastConfig);
            }
        } catch (err) {
            const errorMessage = err?.response?.data?.message ||
                'Apologies, an issue occurred. Please try again later.';
            toast.error(errorMessage, toastConfig);
        }
    };

    const handlePromoCodeChange = (event) => {

        setPromoCode(event.target.value?.toUpperCase());
    };

    return {promoCode, setPromoCode, checkPromoCode, handlePromoCodeChange};
};

export default usePromoCode;
