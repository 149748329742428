import React from "react";
import {useModal} from "@Contexts/ModalContext";
import ChoosePayment from "@Modals/payment/ChoosePayment";
import EntryBasedPass from "@Modals/payment/EntryBasedPass";

const Modal = () => {
    const {isOpen, closeModal, type} = useModal();

    if (!isOpen) return null;

    return (
        <div className="w-full h-[100vh] bg-modal absolute z-20" onClick={closeModal}>
            <div
                onClick={(e) => e.stopPropagation()}
                className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full xs:h-auto rounded-none xs:w-[85%] md:w-[640px] bg-white xs:rounded-xl shadow-modal p-8">
                { type === 'choosePayment' && <ChoosePayment />}
                { type === 'entryBasedPass' && <EntryBasedPass />}
            </div>
        </div>
    );
};

export default Modal;
