import React from 'react';
import {useModal} from "@Contexts/ModalContext";
import {ReactComponent as ArrowLeft} from '@Icons/payments/ArrowLeft.svg';

const ChoosePayment = () => {
    const {content, closeModal} = useModal();
    const {onChange, availablePaymentProviders} = content;

    return (
        <div className='flex flex-col space-y-8 h-[90vh] sm:h-full'>
            <div className='flex justify-center relative'>
                <button onClick={closeModal}>
                    <ArrowLeft className="absolute top-0 left-2"/>
                </button>
                <p>Метод оплаты</p>
            </div>
            <div className='flex flex-col items-center space-y-3'>
                {availablePaymentProviders?.map(provider => (
                    <button key={provider.paymentType}
                            className="w-full p-4 max-w-[390px] flex items-center space-x-3 border rounded-xl"
                            onClick={() => {
                                onChange(provider.paymentType);
                                closeModal();
                            }}>
                        <img src={provider.img} alt={provider.text} className="h-7"/>
                        <p>{provider.text}</p>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default ChoosePayment;
