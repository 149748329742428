import {createSlice} from '@reduxjs/toolkit';
import {
    abuDhabiCodes,
    ajmanCodes,
    dubaiCodes,
    fujarahCodes, kuwaitCodes,
    rasAlCodes,
    sharjahCodes,
    ummAlQuwainCodes
} from "../static/static";

const initialState = {
    region: "Dubai",
    code: "",
    reverse: false
}

const codesMap = {
    "Dubai": dubaiCodes,
    "Abu Dhabi": abuDhabiCodes,
    "Sharjah": sharjahCodes,
    "Ajman": ajmanCodes,
    "Umm Al Quwain": ummAlQuwainCodes,
    "Ras Al Khaima": rasAlCodes,
    "Fujairah": fujarahCodes,
    "Kuwait": kuwaitCodes
}

const uaeCarFormSlice = createSlice({
    name: 'uaeCarForm',
    initialState,
    reducers: {

        setRegion(state, action) {
            state.region = action.payload;
            state.code = codesMap[action.payload] ? codesMap[action.payload][0] : "";
            // localStorage.setItem('region', action.payload)

        },
        setCode(state, action) {
            state.code = action.payload;
            // localStorage.setItem('code', action.payload)
        },
        setReverse(state, action) {
            state.reverse = action.payload;
            // action.payload ? localStorage.setItem('isReversed', 'true') : localStorage.removeItem('isReversed')

        }
    },
});

export const {setRegion, setCode, setReverse} = uaeCarFormSlice.actions;

export default uaeCarFormSlice.reducer;