import React, {useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
    axiosStripe,
    axiosM2pay,
    billingInfoFunc,
    axiosGetAbonementInfo,
    axiosAbonementCreate,
    getParkingByUid,
    stripePaymentCheck,
} from "@API/axiosRequest";
import "./InfoCar.css";
import {DollarSvg} from "../../../assets/images/DollarSvg";
import {SubscriptionSvg} from "../../../assets/images/SubscriptionSvg";
import {PaymentInfo} from "./PaymentInfo/PaymentInfo";
import {SubscriptionInfo} from "./SubscriptionInfo/SubscriptionInfo";
import {currencySymbols} from "../../../static/static";
import {ParkingStatus} from "@Utils/enums/responseStatus";
import {calculateStayDuration, formatCheckInDate} from "@Utils/helpers/helpers";
import {useSelector} from "react-redux";
import {usePayment} from "../../../contexts/PaymentProvider";
import Loader from "@UI/kit/Loader/Loader";
import Button from "@UI/kit/Button/Button";
import FormContainer from "../../../UI/kit/FormContainer/FormContainer";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {NetworkContext} from "../../../contexts/NetworkContext";
import styles from "../EnterCarForm/enterCarForm.module.scss";

function InfoCar({qr}) {
    const {isOnline} = useContext(NetworkContext);
    const {t} = useTranslation();
    const {setHandlePay} = usePayment();
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const [info, setInfo] = useState(location.state?.info);
    const [plateNumber, setPlateNumber] = useState(location.state?.plateNumber);
    const [parkingUid, setParkingUid] = useState(params.parkingUid || location.state?.parkingUid);
    const [paymentMethod, setPaymentMethod] = useState(location.state?.paymentMethod);

    const [error, setError] = useState("");
    const [payLoading, setPayLoading] = useState(false);
    const [amount, setAmount] = useState(info?.sum || 0);
    const [type, setType] = useState(info?.result !== 'error' ? params.rateType || 'parking' : 'subscription');
    const [isLoading, setIsLoading] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [date, setDate] = useState(new Date());
    const [innerParkings, setInnerParkings] = useState([]);
    const [innerParkingId, setInnerParkingId] = useState(null);
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [parkingInfo, setParkingInfo] = useState({currency: '$', abonementEnabled: true});
    const initialLoad = useRef(true);

    const {region} = useSelector((state) => state.uaeCarForm);

    const [selectedSubscription, setSelectedSubscription] = useState('');

    localStorage.setItem("parkingUid", params.parkingUid);
    localStorage.setItem("paymentMethod", paymentMethod);

    useEffect(() => {
        if (initialLoad.current) {
            fetchParkingInfo();

            if (info.result === ParkingStatus.SUCCESS) {
                localStorage.setItem('inDate', info.inDate);
            }

            if (qr) {
                updateQrParams();
                fetchBillingInfo();
            }
            initialLoad.current = false;
        }
    }, []);

    useEffect(() => {
        if (type === 'subscription') {
            localStorage.setItem('subscription', 'true');
        } else {
            localStorage.removeItem('subscription');
        }
    }, [type]);

    useEffect(() => {
        setHandlePay(() => (info.sum || selectedSubscription.price) ? (type === 'subscription' ? handleSubscriptionPayment : handlePayment) : handleImitation);
    }, [info, selectedSubscription, type]);

    const fetchSubscriptions = async (retryCount = 0) => {
        setIsLoading(true);
        try {
            const response = await axiosGetAbonementInfo(parkingUid, 'en');
            if (response?.message !== 'error') {
                setSubscriptions(response?.message.abonementTypes);
                setInnerParkings(response?.message.innerParkings);
                setInnerParkingId(response?.message.innerParkings[0].id);
            }
        } catch (err) {
            handleFetchError(err, retryCount, fetchSubscriptions);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchParkingInfo = async () => {
        try {
            setIsLoading(true);
            const res = await getParkingByUid(parkingUid);
            localStorage.setItem('parking', JSON.stringify(res.message));
            setParkingInfo({
                isEnabled: res?.message.abonementsEnabled,
                currency: res?.message.currency,
                name: res?.message.name,
            });
            if (res?.message.abonementsEnabled) {
                fetchSubscriptions();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchBillingInfo = async () => {
        await billingInfoFunc(
            localStorage.getItem("billingToken"),
            params.plateNumber,
            params.parkingUid,
            1
        ).then((resp) => setInfo(resp.result));
    };

    const handlePayment = () => {
        if (paymentMethod === "portico") {
            handlePorticoPayment();
        } else if (paymentMethod === "stripe") {
            handleStripePayment();
        } else if (paymentMethod === "m2pay") {
            handleM2PayPayment();
        } else if (paymentMethod === "kaspi") {
            window.location.href = 'https://kaspi.kz/pay/VBRParking';
        };
    };

    const handleSubscriptionPayment = async () => {
        const formattedDate = formatDate(new Date(date));
        setPayLoading(true);

        return await axiosAbonementCreate(plateNumber, parkingUid, innerParkingId, selectedSubscription.id, formattedDate)
            .finally(() => setPayLoading(false));
    };

    const handlePorticoPayment = () => {
        localStorage.setItem("sum", info.sum);
        localStorage.setItem("plateNumber", plateNumber);
        localStorage.setItem("currency", info?.currency);

        navigate(`/parking/${parkingUid}/payment`, {
            state: {
                paymentInfo: {
                    sum: info.sum,
                    plateNumber: plateNumber,
                    currency: info?.currency,
                },
            },
        });
    };

    const handleStripePayment = async () => {
        setPayLoading(true);
        try {
            const resp = await axiosStripe(
                plateNumber,
                amount,
                info?.currency,
                `${window.location.origin}/payment-success/${parkingUid}/${plateNumber}/`,
                `${window.location.origin}/payment-error`,
                parkingUid
            );
            window.open(resp.data?.url, "_self");
        } finally {
            setPayLoading(false);
        }
    };

    const handleM2PayPayment = async () => {
        setPayLoading(true);
        try {
            await axiosM2pay(
                plateNumber,
                info.sum,
                `${window.location.origin}/payment-success/${parkingUid}/${plateNumber}/`,
                `${window.location.origin}/payment-error`,
                parkingUid
            );
        } finally {
            setPayLoading(false);
        }
    };

    const handleFetchError = (err, retryCount, fetchFunction) => {
        if (err.response && err.response.status === 404) {
            if (retryCount < 5) {
                fetchFunction(retryCount + 1);
            } else {
                console.error("Retry limit reached, stopping further attempts.");
            }
        } else {
            console.error("An error occurred: ", err.message);
        }
    };

    const updateQrParams = () => {
        setPlateNumber(params.plateNumber);
        setParkingUid(params.parkingUid);
        setPaymentMethod(params.paymentMethod);
        localStorage.setItem("paymentMethod", params.paymentMethod);
    };

    const handleImitation = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            navigate(`/payment-success/${parkingUid}/${plateNumber}`);
        }, 1500);
    };

    const navigateToInfo = () => {
        if (type === 'subscription') {
            handleSubscriptionPayment().then(() => {
                navigate(`/parking/${parkingUid}/info/subscription/payment`, {
                    state: {
                        info,
                        plateNumber,
                        parkingUid,
                        paymentMethod,
                        subscriptionAmount,
                        startDate: date,
                        type,
                    },
                });
            }).catch((err) => {
                toast.error(err);
            });
        } else {
            navigate(`/parking/${parkingUid}/info/payment`, {
                state: {
                    info,
                    plateNumber,
                    parkingUid,
                    paymentMethod,
                    subscriptionAmount,
                    type,
                },
            });
        }
    };

    if (!isOnline) {
        return (
            <FormContainer>
                <div className="flex w-full flex-col space-y-3 items-center">
                    <p className="text-alertRed text-center">{t("no_internet_connection")}</p>
                </div>
            </FormContainer>
        )
    }

    if (payLoading || isLoading) {
        return (
            <FormContainer>
                <div className="w-full flex justify-center items-center">
                    <Loader/>
                </div>
            </FormContainer>
        )
    }

    return (
        <FormContainer>
            <div className="w-full">
                {renderSubscriptionContent()}
            </div>
        </FormContainer>
    );

    function renderSubscriptionContent() {
        return (
            <div className="w-full flex flex-col space-y-6">
                <PaymentOptions setType={setType} type={type} info={info} parkingInfo={parkingInfo} />
                {renderPaymentInfoOrSubscription()}
                {renderNextButton()}
            </div>
        );
    }

    function renderParkingSessionDetails() {
        return (
            renderParkingInfoDetails()
        );
    }


    function renderPaymentInfoOrSubscription() {

        return type === 'parking' ? (
            <PaymentInfo info={info} parkingUid={parkingUid} plateNumber={plateNumber}/>
        ) : (
            subscriptions?.length > 0 &&
            <SubscriptionInfo
                subscriptions={subscriptions}
                info={info}
                plateNumber={plateNumber}
                selectedSubscription={selectedSubscription}
                setSelectedSubscription={setSelectedSubscription}
                setDate={setDate}
                setInnerParkingId={setInnerParkingId}
                innerParkings={innerParkings}
                setSubscriptionAmount={setSubscriptionAmount}
                parkingUid={parkingUid}
            />
        );
    }

    function renderNextButton() {
        return (
            <div className="w-full flex justify-center">
                {
                    ((info.result !== 0 && type === 'subscription') ||
                    info.result === 0)
                    && <Button onClick={navigateToInfo} text={isLoading ? t('loading') : t('continue')}
                               disabled={!selectedSubscription && type === 'subscription'}/>
                }

            </div>
        );
    }

    function renderParkingInfoDetails() {
        return (
            <>
                <div className="infoCar_name">{parkingInfo?.name}</div>
                <div className="infoCar_info">
                    <span>{t('current_balance')}</span>
                    <span>{formatCurrency(info?.currentBalance, info?.currency)}</span>
                </div>
                <div className="infoCar_info">
                    <span>{t('license_plate')}</span>
                    <span>{plateNumber}</span>
                </div>
                {info?.inDate && (
                    <div className="infoCar_info">
                        <span>{t('entrance_time')}</span>
                        <span>{formatCheckInDate(info.inDate)}</span>
                    </div>
                )}
                <div className="infoCar_info">
                    <span>{t('parking_duration')}</span>
                    <span>{info.durationInMinutes ? formatDuration(info.durationInMinutes) : calculateStayDuration(info.inDate)}</span>
                </div>
                <div className="infoCar_info highlighted">
                    <span>{t('parking_fee')}</span>
                    <span>{formatCurrency(info.sum, info?.currency)}</span>
                </div>
                {info.result === 0 && renderPaymentButton()}
            </>
        );
    }

    function renderPaymentButton() {
        return (
            <div className="w-full flex flex-col items-center">
                <span className="infoCar_additional_info">{t('to_replenish_balance_enter')}</span>
                <Button onClick={handlePaymentOrImitation} text={payLoading ? t('loading') : t('pay')}/>
            </div>
        );
    }

    function handlePaymentOrImitation() {
        if (info.sum) {
            handlePayment();
        } else {
            handleImitation();
        }
    }
}

const PaymentOptions = ({type, setType, info, parkingInfo}) => {
    return (
        <div className="w-full flex space-x-3">
            {info.result !== 'error' &&
                <PaymentOption type={type} setType={setType} optionType="parking" SvgComponent={DollarSvg}/>
            }
            {
                parkingInfo?.isEnabled &&
                <PaymentOption type={type} setType={setType} optionType="subscription" SvgComponent={SubscriptionSvg}/>
            }
        </div>
    )
};

const PaymentOption = ({type, setType, optionType, SvgComponent}) => {
    const isActive = type === optionType;
    const color = isActive ? '#FFF' : '#157FFF';
    const {t} = useTranslation();

    return (
        <div className={`payment-type ${isActive && 'active'}`} onClick={() => setType(optionType)}>
            <SvgComponent color={color}/>
            {optionType === 'parking' ? t('pay_for_parking') : t('parking_permits')}
        </div>
    );
};

function formatCurrency(amount, currency) {
    return `${currencySymbols[currency?.toUpperCase()]}${amount?.toLocaleString("en").replace(/,/g, " ")}`;
}

function formatDuration(durationInMinutes) {
    const hours = Math.floor(Math.abs(durationInMinutes) / 60);
    const minutes = Math.abs(durationInMinutes) % 60;
    return `${hours} ${hours === 1 ? "hour" : "hours"} and ${minutes} minutes`;
}

function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hour}:${minute}`;
}

export default InfoCar;
