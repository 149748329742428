import React from "react";
import {useLocation, useParams} from "react-router-dom";
import GlobalPayments from "@Components/payment/GlobalPayments/GlopalPayments";

function PaymentForm() {
    const location = useLocation();
    const params = useParams();
    let paymentInfo = location.state?.paymentInfo;
    paymentInfo["parkingUid"] = params?.parkingUid;

    return (
        <>
            <GlobalPayments paymentInfo={paymentInfo}/>
        </>
    );
}

export default PaymentForm;
