import React, { useState, useEffect } from 'react';
import Input from '@UI/kit/Input/Input';
import Button from '@UI/kit/Button/Button';
import Terms from '@UI/kit/Terms/Terms';
import Rights from '@UI/kit/Rights/Rights';
import FormContainer from "@UI/kit/FormContainer/FormContainer";
import { createLandingClient } from "@API/axiosRequest";
import { useNavigate } from "react-router-dom";
import { getParkingByUid } from "@API/axiosRequest";
import Loader from "@UI/kit/Loader/Loader";
import { useTranslation } from "react-i18next";

const NightPass = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [plateNumber, setPlateNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [parking, setParking] = useState('');
    const [parkingUid, setParkingUid] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const params = new URLSearchParams(window.location.search);

    // Set the language and synchronize with i18n
    useEffect(() => {
        const savedPlateNumber = localStorage.getItem('plateNumber');
        const savedPhoneNumber = localStorage.getItem('phoneNumber');
        const savedEmail = localStorage.getItem('email');

        if (savedPlateNumber) setPlateNumber(savedPlateNumber);
        if (savedPhoneNumber) setPhoneNumber(savedPhoneNumber);
        if (savedEmail) setEmail(savedEmail);

        setParkingUid(params.get('source'));

        const locale = params.get('locale');
        if (locale && locale !== i18n.language) {
            const targetLanguage = locale === 'kz' ? 'kk' : locale;
            i18n.changeLanguage(targetLanguage);
            localStorage.setItem('locale', targetLanguage);
        }

        // Добавление слушателя изменения языка
        const handleLanguageChanged = (lng) => {
            if (lng !== i18n.language) {
                setPlateNumber(''); // Здесь можно сбросить или обновить состояние при изменении языка
            }
        };

        i18n.on('languageChanged', handleLanguageChanged);

        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [params.toString()]); // Теперь зависимость основана на URL-параметрах

    useEffect(() => {
        localStorage.setItem('plateNumber', plateNumber);
        localStorage.setItem('phoneNumber', phoneNumber);
        localStorage.setItem('email', email);
    }, [plateNumber, phoneNumber, email]);

    useEffect(() => {
        if (parkingUid) {
            getParkingByUid(parkingUid).then((res) => {
                localStorage.setItem('parking', JSON.stringify(res.message));
                setParking(res.message);
            });
        }
    }, [parkingUid]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const res = await createLandingClient(phoneNumber, email, plateNumber);
        if (res) {
            localStorage.setItem('userId', res?.userId);
            navigate('/parking/night-pass/abonements',
                {
                    state: {
                        parking,
                        userId: res?.userId,
                        plateNumber,
                        email
                    }
                });
        }
        setIsLoading(false);
    };

    return (
        <FormContainer>
            <div>
                <div className="flex flex-col items-center space-y-1 mb-6">
                    <p className="text-base">{t('subscription_purchase')}</p>
                    <p className="text-xs text-normal">{parking && parking?.name.toUpperCase()}</p>
                </div>
                <p className="text-center text-sm text-disabled">{t('enter_your_details')}</p>
            </div>

            <form onSubmit={handleSubmit} className="w-full flex flex-col items-center space-y-6">
                <Input
                    type="text"
                    label={t('plate_number')}
                    value={plateNumber.toUpperCase()}
                    maxLength={10}
                    onChange={(e) => setPlateNumber(e.target.value.toUpperCase())}
                />
                <Input
                    type="text"
                    label={t('phone_number')}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <Input
                    type="email"
                    label={t('email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {
                    isLoading ? <Loader /> : <Button
                        type="submit"
                        text={t('continue')}
                    />
                }
                <Terms />
            </form>
            {/*<div className="absolute bottom-3 flex flex-col">*/}
            {/*    <Rights />*/}
            {/*</div>*/}
        </FormContainer>
    );
};

export default NightPass;
