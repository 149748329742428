import React from 'react';
import {useModal} from "@Contexts/ModalContext";
import {ReactComponent as Close} from "@Icons/payments/Close.svg";

const EntryBasedPass = () => {
    const {content, closeModal} = useModal();

    return (
        <div className="flex flex-col space-y-5">
            <div className="flex w-full justify-between">
                <p>Entry-Based pass</p>
                <button onClick={closeModal}>
                    <Close/>
                </button>
            </div>
            <p className="text-textMuted text-sm">
                The permit is valid for a year.
                Entry is available during parking hours, if you stay another day, you do not waste your pass.
            </p>
        </div>
    );
};

export default EntryBasedPass;