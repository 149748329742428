import React from 'react';
import clsx from 'clsx';


const Button = ({text, onClick, styles, type, disabled, textStyles}) => {
    return (
        <button className={clsx("px-6 py-2 rounded-2xl text-white h-14 w-full max-w-[300px]", styles ? styles : 'bg-btnBlue hover:bg-btnBlueHover', disabled && 'opacity-40')} onClick={onClick}
                type={type} disabled={disabled}>
            <p className={textStyles}>{text}</p>
        </button>
    );
};

export default Button;