import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React, {useState, useEffect} from "react";
import Loader from "@UI/kit/Loader/Loader";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SwitchButton from "@UI/kit/SwitchButton/SwitchButton";
import {currencySymbols} from "@Static/static";
import {ReactComponent as QuestionOutlined} from "@Icons/payments/QuestionOutlined.svg";
import {useModal} from "@Contexts/ModalContext";
import {ReactComponent as ArrowBottom} from "@Icons/payments/ArrowBottom.svg";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

export const SubscriptionInfo = ({
                                     info,
                                     plateNumber,
                                     selectedSubscription,
                                     setSelectedSubscription,
                                     subscriptions,
                                     setDate,
                                     setSubscriptionAmount,
                                     setInnerParkingId,
                                     innerParkings,
                                     parkingUid
                                 }) => {
    const [isCurrentSession, setIsCurrentSession] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const [activeSubscriptions, setActiveSubscriptions] = useState([]);
    const [activeParking, setActiveParking] = useState([]);
    const [activePermitTypes, setActivePermitTypes] = useState([]);
    const [activePermitType, setActivePermitType] = useState();
    const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
    const {openModal} = useModal();
    const {t} = useTranslation();

    useEffect(() => {
        initializeParkingAndSubscriptions();
    }, []);

    const initializeParkingAndSubscriptions = () => {
        const filteredParking = filterParkingWithSubscriptions(innerParkings, subscriptions);
        const initialFilteredSubscriptions = filterSubscriptionsByParking(filteredParking[0]?.id, subscriptions);
        const initialActivePermitTypes = filterAvailablePermitTypes(initialFilteredSubscriptions);
        const initialActiveSubscriptions = filterAvailableSubscriptions(initialFilteredSubscriptions, initialActivePermitTypes[0]);

        setActiveParking(filteredParking);
        setFilteredSubscriptions(initialFilteredSubscriptions);
        setActivePermitTypes(initialActivePermitTypes);
        localStorage.setItem('permitType', initialActivePermitTypes[0]);
        localStorage.setItem('selectedSubscription', '');
        setActiveSubscriptions(initialActiveSubscriptions);
    };

    const handleParkingChange = (e) => {
        const parkingId = Number(e.target.value);
        updateSubscriptionsForParking(parkingId);
        localStorage.setItem('permitType', '');
        localStorage.setItem('selectedSubscription', '');
        setSelectedSubscription('');
    };

    const handlePermitChange = (e) => {
        const permitType = e.target.value;
        updateSubscriptionsForPermitType(permitType);
    };

    const updateSubscriptionsForParking = (parkingId) => {
        const filteredSubscriptions = filterSubscriptionsByParking(parkingId, subscriptions);
        const activePermitTypes = filterAvailablePermitTypes(filteredSubscriptions);
        const activeSubscriptions = filterAvailableSubscriptions(filteredSubscriptions, activePermitTypes[0]);

        setInnerParkingId(parkingId);
        setFilteredSubscriptions(filteredSubscriptions);
        setActivePermitTypes(activePermitTypes);
        setActiveSubscriptions(activeSubscriptions);
    };

    const updateSubscriptionsForPermitType = (permitType) => {
        const filteredSubscriptionsByPermit = filterAvailableSubscriptions(filteredSubscriptions, permitType);

        setActivePermitType(permitType);
        setActiveSubscriptions(filteredSubscriptionsByPermit);
        localStorage.setItem('permitType', permitType);
        localStorage.setItem('selectedSubscription', '');
        setSelectedSubscription('');
    };

    const renderSubscriptionContent = () => {
        const subscriptionDisabled = localStorage.getItem('region') === 'Saudi Arabia';

        if (subscriptionDisabled) {
            return renderDisabledMessage();
        }

        return (
            <>
                {renderParkingZoneSelect()}
                {renderPermitTypeSelect()}
                {renderPermitDurationCarousel()}
                {selectedSubscription && renderDateSelector()}
            </>
        );
    };

    return (
        <div className="subscription-info-wrapper">
            {activeSubscriptions && renderSubscriptionContent()}
        </div>
    );

    function renderParkingZoneSelect() {
        return activeParking.length > 0 && (
            <SelectWrapper label={t('parking_zone')}>
                <select onChange={handleParkingChange} className="border rounded-2xl p-4 appearance-none">
                    {activeParking.map(p => (
                        <option key={p.id} value={p.id}>
                            {p.name}
                        </option>
                    ))}
                </select>
            </SelectWrapper>
        );
    }

    function renderPermitTypeSelect() {
        return activeParking.length > 0 && (
            <SelectWrapper label={t('select_permit_type')}>
                <select onChange={handlePermitChange} className="border rounded-2xl p-4 appearance-none">
                    {activePermitTypes.map(type => (
                        <option key={type} value={type}>
                            {type}
                        </option>
                    ))}
                </select>
            </SelectWrapper>
        );
    }

    function renderPermitDurationCarousel() {
        const responsive = {
            superLargeDesktop: {breakpoint: {max: 4000, min: 1024}, items: 2},
            desktop: {breakpoint: {max: 1024, min: 768}, items: 2},
            tablet: {breakpoint: {max: 768, min: 640}, items: 2},
            mobile: {breakpoint: {max: 640, min: 0}, items: 2},
        };

        return activeSubscriptions.length > 0 && (
            <div className="plans">
                <label className="text-textLoud text-base font-medium">{t('select_permit_duration')}</label>
                <Carousel responsive={responsive} showDots={false} arrows={false}>
                    {activeSubscriptions.map(sub => (
                        <div key={sub.id} className={activeSubscriptions.length > 2 && 'h-[84px]'}>
                            <SubscriptionCard
                                subscription={sub}
                                selectedSubscription={selectedSubscription}
                                setSelectedSubscription={setSelectedSubscription}
                            />
                        </div>
                    ))}
                </Carousel>
            </div>
        );
    }

    function renderDateSelector() {
        return activePermitType !== 'Entry-Based pass' ? (
            <DateSelector
                info={info}
                parkingUid={parkingUid}
                setDate={setDate}
                isCurrentSession={isCurrentSession}
                setIsCurrentSession={setIsCurrentSession}
                isActive={isActive}
                setIsActive={setIsActive}
            />
        ) : (
            <div className="flex flex-col space-y-4">
                <button className="flex space-x-1" onClick={() => openModal('entryBasedPass')}>
                    <p className="text-link">{t('tariff_details')}</p>
                    <QuestionOutlined/>
                </button>
                <div className="flex flex-col space-y-3">
                    <p>{t('start_date')}</p>
                    <p className="text-textMuted text-sm">{t('from_next_entry')}</p>
                </div>
            </div>
        );
    }

    function renderDisabledMessage() {
        return (
            <div className="disabled-message">
                <span>{t('apologies_to_permit')}</span>
            </div>
        );
    }
};


const SelectWrapper = ({label, children}) => (
    <div className="flex flex-col space-y-2 relative">
        <label className="text-textLoud text-base font-medium">{label}</label>
        {children}
        <ArrowBottom className="absolute top-11 right-3"/>
    </div>
);

const SubscriptionCard = ({subscription, selectedSubscription, setSelectedSubscription}) => (
    <div
        className={clsx('plan', selectedSubscription.id === subscription.id && 'plan-active')}
        onClick={() => {
            localStorage.setItem('selectedSubscription', JSON.stringify(subscription));
            setSelectedSubscription(subscription)
        }}
    >
        <span className="period-days">
            {subscription.numberOfAllowedEntries > 0 && `${subscription.numberOfAllowedEntries} entries / `}
            {subscription.period > 0 && `${subscription.period} days`}
        </span>
        <span>{subscription.price} {currencySymbols[subscription.currency]}</span>
    </div>
);

const DateSelector = ({parkingUid, setDate, isCurrentSession, setIsCurrentSession}) => {
    const { t } = useTranslation();

    return (
        <div className="date-wrapper">
            <label className="text-textLoud text-base font-medium">{t('starts_from')}</label>
            <SessionOption
                isCurrentSession={isCurrentSession}
                setIsCurrentSession={setIsCurrentSession}
            />
            {!isCurrentSession && renderDateTimePicker({parkingUid, setDate})}
        </div>
    )
};

const SessionOption = ({isCurrentSession, setIsCurrentSession}) => {
    const {t} = useTranslation();

    return (
    <div className="session-option">
        <div
            className={`current-session ${isCurrentSession ? 'active' : ''}`}
            onClick={() => setIsCurrentSession(true)}
        >
            {t('current_session')}
        </div>
        <div
            className={`current-session ${!isCurrentSession ? 'active' : ''}`}
            onClick={() => setIsCurrentSession(false)}
        >
            {t('choose_date')}
        </div>
    </div>
);}

const renderDateTimePicker = ({parkingUid, setDate}) => (
    <>
        <MobileDateTimePicker
            format={parkingUid === "118" ? 'LLL' : 'MM/DD/YYYY'}
            onChange={(e) => setDate(e.toDate())}
            defaultValue={dayjs()}
        />
        <IncludeUnpaidSession/>
    </>
);

const IncludeUnpaidSession = ({isActive, setIsActive}) => {
    const {t} = useTranslation();

    return (
    <div className="include-section">
        <div className="switch-wrapper">
            <span>{t('include_unpaid_session')}</span>
            <SwitchButton isActive={isActive} setIsActive={setIsActive}/>
        </div>
        <span className="subdued">{t('turn_on_to_pay')}</span>
    </div>
);
}

const LoaderWrapper = () => (
    <div className="loader-wrapper">
        <Loader/>
    </div>
);

const filterParkingWithSubscriptions = (innerParkings, subscriptions) => {
    return innerParkings.filter(p => subscriptions.some(sub => sub.parkingId === p.id));
};

const filterSubscriptionsByParking = (parkingId, subscriptions) => {
    return subscriptions.filter(sub => sub.parkingId === parkingId);
};

const filterAvailablePermitTypes = (subscriptions) => {
    const permitTypes = new Set();
    subscriptions.forEach(sub => permitTypes.add(getPermitType(sub)));
    return Array.from(permitTypes);
};

const filterAvailableSubscriptions = (subscriptions, permitType) => {
    return subscriptions.filter(sub => getPermitType(sub) === permitType);
};

const getPermitType = (subscription) => {
    if (subscription.type === 'LIMITED_ENTRY') {
        localStorage.setItem('permitType', 'Entry-Based pass');
        return 'Entry-Based pass';
    }
    switch (subscription.periodType) {
        case 'FULL_DAY':
            localStorage.setItem('permitType', 'Unlimited pass');
            return 'Unlimited pass';
        case 'DAILY':
            localStorage.setItem('permitType', 'Day pass');
            return 'Day pass';
        case 'NIGHTLY':
            localStorage.setItem('permitType', 'Night pass');
            return 'Night pass';
        default:
            localStorage.setItem('permitType', 'Unlimited pass');
            return 'Unlimited pass';
    }
};

export default SubscriptionInfo;
