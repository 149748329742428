import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {calculateStayDuration, formatCheckInDate} from "@Utils/helpers/helpers";
import {stripePaymentCheck} from "@API/axiosRequest";
import {currencySymbols} from "@Static/static";
import Button from "@UI/kit/Button/Button";
import FormContainer from "@UI/kit/FormContainer/FormContainer";
import {ReactComponent as Card} from "@Icons/payments/Card.svg";
import {ReactComponent as ArrowRight} from "@Icons/payments/ArrowRight.svg";
import {useTranslation} from "react-i18next";
import {axiosPay, getPaymentProviders, getPaymentProvidersByParkingId} from "@API/axiosRequest";
import {useModal} from "@Contexts/ModalContext";
import Loader from "../../../../UI/kit/Loader/Loader";
import {paymentCheck} from "../../../../api/axiosRequest";
import {NetworkContext} from "../../../../contexts/NetworkContext";

function Payment() {
    const {isOnline} = useContext(NetworkContext);
    const location = useLocation();
    const {info, plateNumber, parkingUid} = location.state;
    const initialLoad = useRef(true);
    const [checkResponse, setCheckResponse] = useState();
    const [paymentProvider, setPaymentProvider] = useState(null);
    const [availablePaymentProviders, setAvailablePaymentProviders] = useState([]);
    const [paymentProviders, setPaymentProviders] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const {openModal} = useModal();

    const check = useCallback(async () => {
        await paymentCheck(plateNumber, parkingUid).then((res) => {
            setCheckResponse(res.data);
        })
    }, []);

    const onChangePayment = (method) => {
        setPaymentProvider(method);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const trimmedPlateNumber = plateNumber?.trim();
        const successUrl = `${window.location.origin}/payment-success/${parkingUid}/${trimmedPlateNumber}`.trim();
        const errorUrl = `${window.location.origin}/payment-error`.trim();

        if (paymentProvider === 'KASPI') {
            window.location.href = availablePaymentProviders.find((method) => method.paymentType === 'KASPI')?.link;
        } else {
            setIsLoading(true);
            await axiosPay(paymentProvider, trimmedPlateNumber, parkingUid, successUrl, errorUrl)
                .then((res) => {
                    window.location.href = res?.data?.url;
                })
                .finally(() => setIsLoading(false));
        }
    };


    useEffect(() => {
        if (initialLoad.current) {
            setIsLoading(true);

            check().then(() => {
                getPaymentProviders().then((providers) => {
                    const methods = providers.data.reduce((acc, provider) => {
                        acc[provider?.providerName] = {
                            img: provider?.iconUrl,
                            text: provider?.providerName
                        };
                        return acc;
                    }, {});

                    getPaymentProvidersByParkingId(parkingUid).then((res) => {
                        const availableMethods = res.data.map(method => ({
                            paymentType: method.paymentType,
                            link: method.link,
                            ...methods[method.paymentType]
                        }));

                        if (availableMethods.length === 1) {
                            setPaymentProvider(availableMethods[0].paymentType);
                        }
                        setAvailablePaymentProviders(availableMethods);
                        setPaymentProviders(methods);
                    })
                });
            }).finally(() => setIsLoading(false));


            initialLoad.current = false;
        }
    }, []);

    if (!isOnline) {
        return (
            <FormContainer>
                <div className="flex w-full flex-col space-y-3 items-center">
                    <p className="text-alertRed text-center">{t("no_internet_connection")}</p>
                </div>
            </FormContainer>
        )
    }

    if (isLoading) {
        return (
            <FormContainer title={<p className="text-textNormal text-2xl mb-4">{t('parking_session_details')}</p>}>
                <Loader/>
            </FormContainer>
        )
    }

    return (
        <FormContainer title={<p className="text-textNormal text-2xl mb-4">{t('parking_session_details')}</p>}>
            <div className="w-full flex flex-col space-y-6 justify-center mb-4">
                <div>
                    <div className="infoCar_info border-none">
                        <span>{t('current_balance')}</span>
                        <span>
                        {info?.currentBalance?.toLocaleString("en").replace(/,/g, " ")
                            || info.current_balance}{" "} {currencySymbols[info?.currency?.toUpperCase()]}
                    </span>
                    </div>
                    <div className="infoCar_info">
                        <span>{t('plate_number')}</span>
                        <span>{plateNumber}</span>
                    </div>
                    {
                        info?.inDate && (
                            <div className="infoCar_info">
                                <span>{t('entrance_time')}</span>
                                <span>{formatCheckInDate(info.inDate)}</span>
                            </div>
                        )
                    }
                    <div className="infoCar_info">
                        <span>{t('parking_duration')}</span>
                        <span>
                        {info?.durationInMinutes ? (
                            info.in_date === ""
                                ? "No entries"
                                : Math.abs(info.durationInMinutes) < 60
                                    ? Math.abs(info.durationInMinutes) + " minutes"
                                    : Math.floor(Math.abs(info.durationInMinutes) / 60) +
                                    ` ${info.durationInMinutes > 1 ? "hours" : "hour"} and ` +
                                    (Math.abs(info.durationInMinutes) % 60) +
                                    " minutes"
                        ) : calculateStayDuration(info.inDate)}
                    </span>
                    </div>
                    <div className="infoCar_info highlighted">
                        <span className="highlighted">{t('total')}:</span>
                        <span>
                        {checkResponse?.sum} {" "}
                            {currencySymbols[info?.currency?.toUpperCase()]}
                    </span>
                    </div>
                </div>

                <button className="mx-auto h-14 w-full max-w-[300px] p-4 border flex items-center rounded-2xl"
                        type="button"
                        onClick={() => {
                            availablePaymentProviders && openModal('choosePayment', {
                                onChange: onChangePayment,
                                availablePaymentProviders
                            })
                        }}
                        disabled={availablePaymentProviders.length === 0}
                >
                    {paymentProvider && (
                        <div className="flex space-x-3 items-center">
                            <img src={paymentProviders[paymentProvider].img} alt="Payment Method"
                                 className="h-7"/>
                            <p>{paymentProviders[paymentProvider].text}</p>
                        </div>
                    )}

                    {!paymentProvider && (
                        <div className="flex space-x-3 items-center">
                            <Card/>
                            <p className="text-sm">{t('choose_payment_method')}</p>
                            <ArrowRight/>
                        </div>
                    )}
                </button>

                    <div className="w-full flex flex-col items-center">
                        <span className="infoCar_additional_info">
                            {t('to_replenish_balance_enter')}
                        </span>
                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            text={isLoading ? t('loading') : t('pay')}
                        />
                    </div>
            </div>
        </FormContainer>
    );
}

export default Payment;
