import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Internet } from "@Icons/payments/Internet.svg";

const LangToggle = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // Функция для капитализации первой буквы
    const capitalizeFirstLetter = (string) => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    };

    // Функция для смены языка
    const handleLanguageChange = (newLanguage) => {
        i18n.changeLanguage(newLanguage);
        localStorage.setItem("locale", newLanguage);
        setLanguage(newLanguage);
        setIsDropdownOpen(false);
    };

    // Функция для открытия/закрытия выпадающего списка
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // useEffect для первоначальной загрузки языка из localStorage
    useEffect(() => {
        const locale = localStorage.getItem("locale");
        if (locale) {
            i18n.changeLanguage(locale);
            setLanguage(locale);
        }

        // Слушатель на изменение языка
        const handleLanguageChanged = (lng) => {
            setLanguage(lng);
        };

        i18n.on('languageChanged', handleLanguageChanged);

        // Удаление слушателя при размонтировании компонента
        return () => {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [i18n]);

    // useEffect для закрытия выпадающего списка при клике вне компонента
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.relative')) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative">
            <button
                onClick={toggleDropdown}
                className="flex items-center space-x-0.5 focus:outline-none"
            >
                <span className="text-link text-sm font-medium">{capitalizeFirstLetter(language)}</span>
                <Internet />
            </button>
            {isDropdownOpen && (
                <div className="absolute right-0 mt-2 bg-white border border-gray-300 rounded shadow-lg">
                    <div
                        onClick={() => handleLanguageChange('en')}
                        className={`p-2 cursor-pointer hover:bg-gray-200 ${language === 'en' ? 'font-bold' : ''}`}
                    >
                        EN
                    </div>
                    <div
                        onClick={() => handleLanguageChange('ru')}
                        className={`p-2 cursor-pointer hover:bg-gray-200 ${language === 'ru' ? 'font-bold' : ''}`}
                    >
                        RU
                    </div>
                    <div
                        onClick={() => handleLanguageChange('kk')}
                        className={`p-2 cursor-pointer hover:bg-gray-200 ${language === 'kk' ? 'font-bold' : ''}`}
                    >
                        KZ
                    </div>
                    <div
                        onClick={() => handleLanguageChange('az')}
                        className={`p-2 cursor-pointer hover:bg-gray-200 ${language === 'az' ? 'font-bold' : ''}`}
                    >
                        AZ
                    </div>
                </div>
            )}
        </div>
    );
};

export default LangToggle;
