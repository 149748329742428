import React from 'react';
import FormContainer from "@UI/kit/FormContainer/FormContainer";
import Button from "@UI/kit/Button/Button";
import Error from "@Images/payments/Error.png"
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const NightPassError = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
        <FormContainer withApps={true}>
            <div className="flex flex-col items-center">
                <img src={Error} alt="Error" className="mb-6"/>
                <p className="mb-9 text-2xl text-center text-surface font-medium">{t('something_went_wrong')}</p>
                <Button text="Попробовать снова" styles="bg-[#F2F4F7] hover:bg-disabled" textStyles="text-link"
                        onClick={() => navigate('/parking/night-pass')}/>
            </div>
        </FormContainer>
    );
};

export default NightPassError;