import React, {createContext, useState, useContext} from 'react';

const ModalContext = createContext();

export const ModalProvider = ({children}) => {
    const [modalState, setModalState] = useState({isOpen: false, type: null});

    const openModal = (type, content) => {
        setModalState({isOpen: true, type, content});
    };

    const closeModal = () => {
        setModalState({isOpen: false, type: null, content: null});
    };

    return (
        <ModalContext.Provider value={{...modalState, openModal, closeModal}}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = () => useContext(ModalContext);
