import React, {useEffect, useState} from 'react';
import FormContainer from "@UI/kit/FormContainer/FormContainer";
import Button from "@UI/kit/Button/Button";
import {ReactComponent as Calendar} from '@Icons/payments/Calendar.svg';
import {ReactComponent as ArrowLeft} from '@Icons/payments/ArrowLeft.svg';
import {MobileDatePicker} from "@mui/x-date-pickers";
import {useLocation, useNavigate} from "react-router-dom";
import {axiosGetAbonementInfo} from "@API/axiosRequest";
import dayjs from "dayjs";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {createAbonement} from "@API/axiosRequest";
import {currencySymbols} from "@Static/static";
import {declensionOfDays} from "@Utils/helpers/helpers";
import Rights from "@UI/kit/Rights/Rights";
import Loader from "@UI/kit/Loader/Loader";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

const responsive = {
    superLargeDesktop: {
        breakpoint: {max: 4000, min: 1024},
        items: 3,
    },
    desktop: {
        breakpoint: {max: 1024, min: 768},
        items: 3
    },
    tablet: {
        breakpoint: {max: 768, min: 640},
        items: 2
    },
    mobile: {
        breakpoint: {max: 640, min: 0},
        items: 2,
    }
};

const NightPassAbonements = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const [parking, setParking] = useState(null);
    const navigate = useNavigate();
    const [selectedAbonement, setSelectedAbonement] = useState(null);
    const [date, setDate] = useState(dayjs());
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [plateNumber, setPlateNumber] = useState('');
    const [userId, setUserId] = useState('');

    const handleSubmit = async () => {
        setIsLoading(true);
        await createAbonement(
            plateNumber,
            parking?.parkingUid,
            selectedAbonement?.parkingId,
            selectedAbonement?.id,
            date,
            userId,
            email
        ).then((res) => {
            localStorage.setItem('receiptId', res?.receiptId);
            navigate('/parking/night-pass/payment', {
                state: {
                    receiptId: res?.receiptId,
                    parking,
                    plateNumber,
                    userId
                }
            });
        }).finally(() => setIsLoading(false));
    };

    useEffect(() => {
        const parking = JSON.parse(localStorage.getItem('parking'));
        const email = localStorage.getItem('email');
        const plateNumber = localStorage.getItem('plateNumber');
        const userId = localStorage.getItem('userId');

        if (parking) {
            setParking(parking);
        }

        if (email) {
            setEmail(email);
        }

        if (plateNumber) {
            setPlateNumber(plateNumber);
        }

        if (userId) {
            setUserId(userId);
        }

    }, []);

    return (
        <FormContainer withApps={false}>
            <div className="relative w-full">
                <div className="flex flex-col w-full items-center space-y-1 mb-6 relative">
                    <p className="text-base">{t('subscription_purchase')}</p>
                    <p className="text-xs text-normal">{parking && parking?.name.toUpperCase()}</p>
                    <button onClick={() => navigate(-1)}>
                        <ArrowLeft className="absolute top-3 left-2"/>
                    </button>
                </div>
            </div>
            <div className="w-full flex flex-col space-y-6">
                {parking ? (
                    <Abonements
                        parking={parking}
                        selectedAbonement={selectedAbonement}
                        setSelectedAbonement={setSelectedAbonement}
                    />
                ) : <Loader/>}
                <SelectDate date={date} setDate={setDate}/>
                <div className="flex flex-col space-y-2">
                    <p>{t('night_subscription')}</p>
                    <p>{t('night_pass_time')}</p>
                    <p className="text-textNormal text-sm">{t('night_pass_description')}</p>
                </div>
            </div>

            {isLoading ? <Loader/> : <Button
                disabled={!selectedAbonement}
                type="button"
                text={t('continue')}
                onClick={handleSubmit}
            />}

            {/*<div className="absolute mb-4 bottom-3 flex flex-col">*/}
            {/*    <Rights/>*/}
            {/*</div>*/}
        </FormContainer>
    );
};

export default NightPassAbonements;

const Abonements = ({parking, selectedAbonement, setSelectedAbonement}) => {
    const {t, i18n} = useTranslation();
    const locale = i18n.language;
    const [abonements, setAbonements] = useState([]);

    useEffect(() => {
        axiosGetAbonementInfo(parking?.parkingUid, 'ru').then(res => {
            setAbonements(res.message);
        });

    }, []);

    const handleAbonementClick = (abonement) => {
        setSelectedAbonement(abonement);
    };

    return (
        <div className="flex flex-col space-y-2">
            <p>{t('subscription_period')}</p>
            {abonements?.abonementTypes && (
                <Carousel
                    responsive={responsive}
                    showDots={false}
                    arrows={false}
                    itemClass="carousel-item-padding-5-px"
                    containerClass="items-start"
                    dotListClass="custom-dot-list-style bottom-0"
                >
                    {abonements.abonementTypes
                        .filter(aboenement => aboenement?.periodType === "NIGHTLY")
                        .map(abonement => (
                            <button
                                key={abonement.id}
                                onClick={() => handleAbonementClick(abonement)}
                                className={clsx('flex flex-col space-y-1 w-[112px] h-[60px] rounded-lg py-2 px-4',
                                    selectedAbonement?.id === abonement.id
                                        ? 'bg-btnBlue text-white'
                                        : 'bg-white text-black border')}
                            >
                                <p className="font-normal text-sm">{declensionOfDays(abonement.period, locale)}</p>
                                <p className="font-normal text-sm">{abonement.price} {currencySymbols[parking?.currency]}</p>
                            </button>
                        ))}
                </Carousel>
            )}
        </div>
    );
};

const SelectDate = ({date, setDate}) => {
    const {t} = useTranslation();
    const [isTodaySelected, setIsTodaySelected] = useState(true);

    const handleTodayClick = () => {
        setIsTodaySelected(true);
        setDate(dayjs());
    };

    const handleDatePickerClick = (newDate) => {
        setIsTodaySelected(false);
        setDate(newDate);
    };

    return (
        <div className="flex flex-col space-y-3">
            <p>{t('subscription_start_date')}</p>
            <div className="flex space-x-2">
                <button
                    className={`flex items-center h-10 px-5 py-2.5 rounded-lg font-normal ${
                        isTodaySelected ? 'bg-btnBlue hover:bg-btnBlueHover text-white' : 'bg-white border'
                    }`}
                    onClick={handleTodayClick}
                >
                    {t('today')}
                </button>
                <div className="w-[180px] flex flex-col space-y-2">
                    <button
                        className={`h-10 flex space-x-2 items-center px-5 py-2.5 rounded-lg font-normal ${
                            !isTodaySelected ? 'bg-btnBlue hover:bg-btnBlueHover text-white' : 'bg-white text-textLoud border'
                        }`}
                        onClick={() => setIsTodaySelected(false)}
                    >
                        <Calendar className={!isTodaySelected ? "stroke-white" : "stroke-textLoud"}/>
                        <p>{t('select_date')}</p>
                    </button>
                    {!isTodaySelected && (
                        <MobileDatePicker
                            format={'MM/DD/YYYY'}
                            value={date}
                            onChange={(newDate) => handleDatePickerClick(newDate)}
                            defaultValue={dayjs()}
                            sx={{
                                '.MuiInputBase-input': {
                                    color: '#0D0D12', // Изменение цвета текста
                                    padding: '10px', // Изменение отступов
                                },
                                '.MuiOutlinedInput-root': {
                                    borderColor: 'red', // Изменение цвета границы
                                    borderRadius: '8px'
                                },
                                '.MuiSvgIcon-root': {
                                    color: 'green', // Изменение цвета иконки
                                },
                                '.MuiPaper-root': {
                                    backgroundColor: '#f0f0f0', // Изменение фона всплывающего календаря
                                },
                                '.MuiPickersDay-root': {
                                    '&.Mui-selected': {
                                        backgroundColor: 'red', // Изменение цвета выбранного дня
                                    }
                                }
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
