import React from "react";
import {calculateStayDuration, formatCheckInDate} from "../../../../utils/helpers/helpers";
import {currencySymbols} from "../../../../static/static";
import {useTranslation} from "react-i18next";

export const PaymentInfo = ({parkingUid, info, plateNumber}) => {
    const isUae = localStorage.getItem('isUae');
    const parking = JSON.parse(localStorage.getItem('parking'));
    const {t} = useTranslation();

    return (
        <div>
              <span className="infoCar_name">
                {parking?.name}
              </span>

            <div className="flex flex-col mt-4">
                <div className={'info-wrapper'}>
                    {
                        info.result === 0 &&
                        (
                            <div className={'info-list'}>
                                <span className={'subdued'}>{t('balance')}</span>
                                <span className={'value'}>{info?.currentBalance
                                    ?.toLocaleString("en")
                                    .replace(/,/g, " ") || info.current_balance}{" "}
                                    {currencySymbols[info?.currency?.toUpperCase()]}</span>
                            </div>
                        )
                    }
                    {
                        info?.inDate && (
                            <div className={'info-list'}>
                                <span className={'subdued'}>{t('check_in_time')}</span>
                                <span className={'value'}>{formatCheckInDate(info.inDate)}</span>
                            </div>
                        )
                    }
                    <div className={'info-list'}>
                        <span className={'subdued'}>{t('vehicle_number')}</span>
                        <span className={'value'}>{plateNumber}</span>
                    </div>
                    {
                        (info.result !== 0) ?
                            (
                                <div className="flex flex-col justify-center items-center py-10">
                                        <span className="text-textNormal text-sm">{t('free_exit_before')}</span>
                                        <span className="text-center">
                                            {
                                                formatCheckInDate(
                                                    new Date(new Date().getTime() + ((info.leftFreeTimeMinutes) * 60000)).toISOString()
                                                )
                                            }
                                        </span>
                                </div>
                            ) :
                            (
                                <>
                                    <div className={'info-list'}>
                                        <span className={'subdued'}>{t('duration')}</span>
                                        <span className={'value'}>{calculateStayDuration(info.inDate)}</span>

                                    </div>
                                    <div className={'info-list'}>
                                        <span className={'amount'}>{t('amount')}</span>
                                        <span className={'amount'}
                                              style={{textAlign: 'right'}}>{info?.sum}{" "}
                                            {currencySymbols[info?.currency?.toUpperCase()]}</span>

                                    </div>
                                </>
                            )
                    }
                </div>
            </div>
        </div>
    )
}
