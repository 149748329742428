import axios from "axios";
import {
    promocodeList,
    promocodeGenerate,
    promoCodeSettings,
    userInfo,
    promocodeTranslations
} from "../endpoints";
import {decodeJWT} from "@Utils/helpers/helpers";
import {toast} from 'react-toastify';

const apiBaseUrl = process.env.REACT_APP_API_PARQOUR;

const createAxiosConfig = (method, url, data = null, token = true) => {
    const headers = token ? {Authorization: `Bearer ${localStorage.getItem("accessToken")}`} : {
        'Content-Type': 'application/json'
    };
    return {method, url: apiBaseUrl + url, headers, data};
};

const createAxiosPublicConfig = (method, url, data = null, token = true) => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept-Language': localStorage.getItem('locale')
    };
    return {method, url: apiBaseUrl + url, headers, data};
};

class ServiceUnavailableError extends Error {
    constructor(message) {
        super(message);
        this.name = "ServiceUnavailableError";
        this.status = "unavailable";
    }
}

class InternalServerError extends Error {
    constructor(message) {
        super(message);
        this.name = "InternalServerError";
        this.status = "unavailable";
    }
}

class NoInternetError extends Error {
    constructor(message) {
        super(message);
        this.name = "NoInternetError";
        this.status = "NO INTERNET";
    }
}

const handleAxiosError = (err) => {
    if (axios.isCancel(err) || err.response?.status === 503) {
        throw new ServiceUnavailableError("Service is unavailable now, please try again later");
    } else if (axios.isCancel(err) || (err.response?.status === 500 && err.response?.error === "Internal Server Error")) {
        throw new InternalServerError("Something goes wrong, please try again later");
    } else if (!window.navigator.onLine && !err.response && err.code === "ERR_NETWORK") {
        throw new NoInternetError("No internet connection");
    } else {
        throw err;
    }
};

export const axiosLogin = async (username, password) => {
    const data = new URLSearchParams();
    data.append('client_id', 'springsecurity');
    data.append('client_secret', 'JCjYNKvrdFsTjPVZQWAhdfvVXLgL7k6p');
    data.append('username', username);
    data.append('password', password);
    data.append('grant_type', 'password');

    const config = {
        method: 'post',
        url: 'https://keycloak-admin.parqour.com/realms/promoservice/protocol/openid-connect/token',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
    };

    try {
        const response = await axios(config);
        localStorage.setItem('accessToken', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
        const decodedToken = decodeJWT(response.data.access_token);
        return decodedToken;
    } catch (err) {
        const errorDescription = err.response?.data?.error_description || err.message;
        toast.error("Login failed: " + errorDescription);
        handleAxiosError(err);
    }
};

export const axiosPromocodeList = async (page, prefix, pageSize, statuses, dateTo, dateFrom, searchTerm) => {
    const config = createAxiosConfig("GET", promocodeList, null);
    config.params = {
        page,
        size: pageSize,
        prefix,
        fromDate: dateFrom,
        toDate: dateTo,
        statuses: statuses || 'GIVEN,EXPIRED,USED',
        code: searchTerm
    };

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const axiosGeneratePromoCodes = async (promoCodeAmount, toCompany, promoTypeId) => {
    const config = createAxiosConfig("post", promocodeGenerate, {promoCodeAmount, toCompany, promoTypeId});

    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const axiosCheckPromocode = async (promocode) => {
    const config = createAxiosConfig("GET", `/promocode/check/${promocode}`);

    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        handleAxiosError(err);
    }
};

export const axiosSendPromocode = async (code, plateNumber) => {
    const config = createAxiosPublicConfig("POST", "/promocode/send", {code, plateNumber});
    config.timeout = 5000;

    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        handleAxiosError(err);
    }
};

export const axiosGetCompanies = async () => {
    const config = createAxiosConfig("GET", `/promocode/parkings/companies`);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const axiosAddCompany = async (companyName) => {
    const config = createAxiosConfig("POST", `/promocode/parkings/companies/${companyName}`);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const axiosGetPromocodeInfo = async (promocode) => {
    const config = createAxiosConfig("GET", `/promocode/info/${promocode}`);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        handleAxiosError(err);
    }
};

export const axiosGetPublicPromoCodeInfo = async (promocode) => {
    const config = createAxiosConfig("GET", `/promocode/public/info/${promocode}`, null, false);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        handleAxiosError(err);
    }
};

export const axiosGetUserInfo = async () => {
    const config = createAxiosConfig("GET", `/promocode/user/info`);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const axiosGetCompaniesInfo = async () => {
    const config = createAxiosConfig("GET", `/promocode/user/companies/info`);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const axiosChangePromocodeStatus = async (id) => {
    const config = createAxiosPublicConfig("POST", `/promocode/status`, {id, status: "GIVEN"});

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const axiosPromocodeActiveList = async (page) => {
    const config = createAxiosConfig("GET", "/promocode/codes/active");
    config.params = {page};

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getPrefixesInfo = async () => {
    const config = createAxiosConfig("GET", "/promocode/prefixes/info");

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const axiosGetPromocodeTypes = async () => {
    const config = createAxiosConfig("GET", "/promocode/types");

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const axiosGetPromocodeTypesByCompany = async (companyId) => {
    const config = createAxiosConfig("GET", `/promocode/byCompany/types/${companyId}`);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const axiosGetPromoUserTypes = async () => {
    const config = createAxiosConfig("GET", "/promocode/user/types");

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const axiosCreatePromocodeType = async (companyId, type, prefix, value, limitValue, expirationHours, description) => {
    const config = createAxiosConfig("POST", `/promocode/types/create/${companyId}`, {
        type,
        prefix,
        value,
        limitValue: limitValue || 1,
        expirationHours,
        description: description || " ",
    });

    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        console.log(err);
    }
};

export const axiosDeactivatePromocodeType = async (id) => {
    const config = createAxiosConfig("POST", `/promocode/types/deactivate/${id}`);

    try {
        const response = await axios(config);
        return response;
    } catch (err) {
        console.log(err);
    }
};

export const getGenerationHistory = async (id) => {
    const config = createAxiosConfig("GET", `/promocode/byCompany/generation/history/${id}`);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getCompanyUsers = async (company_name) => {
    const config = createAxiosConfig("GET", `/promocode/user/list/${company_name}`);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const sendRegistrationInvite = async (data) => {
    const config = createAxiosConfig("POST", `/promocode/auth/registration/invite`, data);

    try {
        const response = await axios(config);
        toast.success('Success: ' + response?.data?.message)
        return response.data;
    } catch (err) {
        toast.error("Failed: " + err.message);
    }
};

export const confirmRegistration = async (data) => {
    const config = createAxiosConfig("POST", `/promocode/public/registration/confirm`, data, false);

    try {
        const response = await axios(config);
        toast.success("Success: " + response?.data?.message);
        return response.data;
    } catch (err) {
        toast.error("Failed: " + err.message);
    }
};

export const getRegistrationInfo = async (id) => {
    const config = createAxiosConfig("GET", `/promocode/public/registration/invite/${id}`, null, false);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        toast.error("Failed: " + err.message);
    }
};

export const deleteCompany = async (company_id) => {
    const config = createAxiosConfig("DELETE", `/promocode/user/company/${company_id}`);

    try {
        const response = await axios(config);
        toast.success("Success: The company was deleted successfully!")
        return response.data;
    } catch (err) {
        toast.error("Failed: " + err.message);
    }
};

export const getGenerationMaxValue = async () => {
    const config = createAxiosConfig("GET", promoCodeSettings);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getGenerationUserMaxValue = async () => {
    const config = createAxiosConfig("GET", userInfo);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const deletePromoCodesBundle = async (id, reduceAmount) => {
    const config = createAxiosConfig("DELETE", `/promocode/delete/list/${id}?reduceAmount=${reduceAmount}`);

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
};

export const getPromocodeTranslations = async () => {
    const config = createAxiosConfig("GET", promocodeTranslations, null, false)

    try {
        const response = await axios(config);
        return response.data;
    } catch (err) {
        console.log(err);
    }
}

export const recoveryPassword = async (email) => {
    const config = createAxiosConfig("POST", "/promocode/public/password/change", email, false);

    try {
        const response = await axios(config);
        toast.success(" The email has been sent successfully!")
        return response.data;
    } catch (err) {
        const errorDescription = err.response?.data?.message || err.response?.data?.error_description || err.message;
        toast.error("Failed: " + errorDescription);
    }
}

export const changePassword = async (data) => {
    const config = createAxiosConfig("POST", "/promocode/public/password/confirm", data, false);

    try {
        const response = await axios(config);
        toast.success("Success: " + response?.data?.message);
        return response.data;
    } catch (err) {
        const errorDescription = err.response?.data?.error_description || err.message;
        toast.error("Failed: " + errorDescription);
    }
}
