import React, {useEffect, useState} from 'react';
import FormContainer from "@UI/kit/FormContainer/FormContainer";
import Button from "@UI/kit/Button/Button";
import {getLandingReceipt} from "@API/axiosRequest";
import {useLocation, useNavigate} from "react-router-dom";
import {ReactComponent as ArrowLeft} from '@Icons/payments/ArrowLeft.svg';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/kk';
import {currencySymbols} from "@Static/static";
import {ReactComponent as Card} from "@Icons/payments/Card.svg";
import {ReactComponent as ArrowRight} from "@Icons/payments/ArrowRight.svg";
import {useModal} from "@Contexts/ModalContext";
import {axiosPay, getPaymentProviders, getPaymentProvidersByParkingId} from "@API/axiosRequest";
import Loader from "@UI/kit/Loader/Loader";
import {useTranslation} from "react-i18next";

const NightPassPayment = () => {
    const {t, i18n} = useTranslation();
    const {openModal} = useModal();
    const location = useLocation();
    const {userId, receiptId, parking, plateNumber} = location.state;
    const navigate = useNavigate();
    const [receipt, setReceipt] = useState({});
    const [paymentProvider, setPaymentProvider] = useState(null);
    const [availablePaymentProviders, setAvailablePaymentProviders] = useState([]);
    const [paymentProviders, setPaymentProviders] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const formatDateTime = (dateString) => {
        dayjs.locale(i18n.language);
        return dayjs(dateString).format('D MMMM YYYY, HH:mm');
    };

    const onChangePayment = (method) => {
        setPaymentProvider(method);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (paymentProvider === 'KASPI') {
            window.location.href = availablePaymentProviders.find((method) => method.paymentType === 'KASPI')?.link;
        } else {
            setIsLoading(true);
            await axiosPay(paymentProvider, plateNumber, parking?.parkingUid,
                `${process.env.REACT_APP_BASE_URL}/parking/night-pass/success/${receiptId}`,
                `${process.env.REACT_APP_BASE_URL}/parking/night-pass/error`,
                userId
            ).then((res) => {
                window.location.href = res.data?.url;
            }).finally(() => setIsLoading(false));
        }
    }

    useEffect(() => {
        getLandingReceipt(receiptId).then((res) => {
            setReceipt({
                begin: res.data.abonement.begin,
                end: res.data.abonement.end,
                price: res.data.abonement.price,
            });
        });
    }, []);

    useEffect(() => {
        getPaymentProviders().then((providers) => {
            const methods = providers.data.reduce((acc, provider) => {
                acc[provider?.providerName] = {
                    img: provider?.iconUrl,
                    text: provider?.providerName
                };
                return acc;
            }, {});

            getPaymentProvidersByParkingId(parking?.parkingUid).then((res) => {

                const availableMethods = res.data.map(method => ({
                    paymentType: method.paymentType,
                    link: method.link,
                    ...methods[method.paymentType]
                }));

                if (availableMethods.length === 1) {
                    setPaymentProvider(availableMethods[0].paymentType);
                }
                setAvailablePaymentProviders(availableMethods);
                setPaymentProviders(methods);
            })
        });
    }, [])

    return (
        <FormContainer withApps={false}>
            <form onSubmit={handleSubmit}>
                <div className="mb-[52px]">
                    <div className="relative w-full">
                        <div className="flex flex-col w-full items-center space-y-1 mb-6 relative">
                            <p className="text-base">{t('subscription_purchase')}</p>
                            <p className="text-xs text-normal">{parking && parking?.name.toUpperCase()}</p>
                            <button onClick={() => navigate(-1)} type="button">
                                <ArrowLeft className="absolute top-3 left-2"/>
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col space-y-2 mb-6">
                        <p>{t('night_subscription')}</p>
                        <p>{t('night_pass_time')}</p>
                        <p className="text-textNormal text-sm">{t('night_pass_description')}</p>
                    </div>
                    <div className="flex flex-col space-y-2 w-full mb-4">
                        <div className="flex justify-between items-center">
                            <p className="text-textNormal text-sm">{t('start_date')}</p>
                            <p className="text-textMuted text-sm">{formatDateTime(receipt?.begin)}</p>
                        </div>
                        <div className="flex justify-between items-center">
                            <p className="text-textNormal text-sm">{t('end_date')}</p>
                            <p className="text-textMuted text-sm">{formatDateTime(receipt?.end)}</p>
                        </div>
                    </div>
                    <div className="w-full flex justify-between">
                        <p>{t('total_cost')}</p>
                        <p>{receipt?.price} {currencySymbols[parking?.currency]}</p>
                    </div>
                </div>
                <div className="flex flex-col space-y-2 w-full items-center">
                        <button className="h-14 w-full max-w-[300px] p-4 border flex items-center rounded-2xl"
                                type="button"
                                onClick={() => {
                                    availablePaymentProviders && openModal('choosePayment', {
                                        onChange: onChangePayment,
                                        availablePaymentProviders
                                    })
                                }}
                                disabled={availablePaymentProviders.length === 0}
                        >
                            {paymentProvider && (
                                <div className="flex space-x-3 items-center">
                                    <img src={paymentProviders[paymentProvider].img} alt="Payment Method"
                                         className="h-7"/>
                                    <p>{paymentProviders[paymentProvider].text}</p>
                                </div>
                            )}

                            {!paymentProvider && (
                                <div className="flex space-x-3 items-center">
                                    <Card/>
                                    <p className="text-sm">{t('choose_payment_method')}</p>
                                    <ArrowRight/>
                                </div>
                            )}
                        </button>

                    {
                        isLoading ? <Loader/> : <Button
                            type="submit"
                            text={t("pay")}
                        />
                    }
                </div>
            </form>
        </FormContainer>
    );
};

export default NightPassPayment;
