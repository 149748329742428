import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

import successImg from "@Images/success-img.png";
import FormContainer from "@UI/kit/FormContainer/FormContainer";
import Button from "@UI/kit/Button/Button";
import {paymentCheck} from "../../../api/axiosRequest";
import {getFreeTimeBeforeExit} from "../../../utils/helpers/helpers";

const PaymentSuccessForm = () => {
    const {parkingUid, plateNumber} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const initialLoad = useRef(true);
    const [freeTimeBeforeExit, setFreeTimeBeforeExit] = useState();
    const isUae = localStorage.getItem('isUae');


    const onNavigate = () => {
        const referrer = localStorage.getItem('referrer');

        if (referrer && referrer === 'turaq') {
            window.location.href = process.env.REACT_APP_TURAQ_URL;
            localStorage.removeItem('referrer');
            return;
        }

        if (isUae) {
            navigate(
                `/uae/parking/${parkingUid}/${localStorage.getItem("paymentMethod")}`
            );
        } else {
            navigate(
                `/${parkingUid}`
            );
        }
    }

    useEffect(() => {
        if (initialLoad.current) {
            paymentCheck(plateNumber, parkingUid).then((res) => {
                if (res.data?.leftFreeTimeMinutes) {
                    setFreeTimeBeforeExit(getFreeTimeBeforeExit(
                        res.data?.leftFreeTimeMinutes))
                }
            });
            initialLoad.current = false;
        }
    }, []);

    return (
        <FormContainer>
            <div className="flex justify-center">
                <img src={successImg} alt="success"/>
            </div>
            <div className="flex flex-col justify-center items-center mb-2.5">
                <span className="infoCar_name">{t('success')}</span>
                <span className="infoCar_address fn-16px mt-2.5">
                    {t('payment_was_successful')}
                </span>
            </div>

            <div>
                {
                    freeTimeBeforeExit &&
                    (
                        <div className="flex flex-col mb-5">
                            <span className="text-center text-textNormal text-sm">{t('free_exit_before')}</span>
                            <span className="text-center">
                                {freeTimeBeforeExit}
                              </span>
                        </div>
                    )
                }

                <Button
                    text={t('continue')}
                    onClick={() => {
                        isUae && localStorage.removeItem('isUae')
                        onNavigate();
                    }}
                />

            </div>
        </FormContainer>
    );
};

export default PaymentSuccessForm;
