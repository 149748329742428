import React from 'react';
import {useTranslation} from "react-i18next";

const Terms = () => {
    const {t} = useTranslation();
    return (
        <div className="w-full flex justify-center items-center">
            <p className="text-center text-textNormal font-normal">{t('read_and_agree')}<br/>
                <a
                    className="text-link"
                    href="https://parqour.kz/wp-content/uploads/2023/10/terms-of-use-en.pdf"> {t('terms_of_use_site')}</a></p>
        </div>
    );
};

export default Terms;