import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import App from "./App";
import store from './app/store'
import "./index.css";
import {PaymentProvider} from "./contexts/PaymentProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <PaymentProvider>
        <Provider store={store}>
            <App />
        </Provider>
        </PaymentProvider>
    </React.StrictMode>
);
