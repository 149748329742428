import React, {useCallback, useEffect, useRef, useState} from 'react';
import Button from "@UI/kit/Button/Button";
import Success from "@Images/payments/Success.png";
import {useNavigate, useParams} from "react-router-dom";
import {getLandingReceipt} from "@API/axiosRequest";
import {currencySymbols} from "@Static/static";
import {sendLandingLetter} from "@API/axiosRequest";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/kk';
import {useTranslation} from "react-i18next";
import FormContainer from "@UI/kit/FormContainer/FormContainer";

const NightPassSuccess = () => {
    const {receiptId} = useParams();
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [receipt, setReceipt] = useState();
    const [parking, setParking] = useState({});
    const [email, setEmail] = useState('');
    const [plateNumber, setPlateNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const initialLoad = useRef(true);

    const formatDate = (dateString) => {
        dayjs.locale(i18n.language);
        return dayjs(dateString).format('D MMMM YYYY, HH:mm');
    };

    const sendLetter = useCallback(async (userEmail, parkingName, parkingAddress, plateNumber, dateStart, dateEnd, sum, currency) => {
        sendLandingLetter(userEmail, parkingName, parkingAddress, plateNumber, dateStart, dateEnd, sum, currency);
    }, []);

    useEffect(() => {
        if (initialLoad.current) {
            setIsLoading(true);
            getLandingReceipt(receiptId).then((res) => {
                const receipt = res?.data;
                setReceipt(receipt.abonement);

                sendLetter(
                    res.data?.email,
                    receipt.abonement?.parking.address,
                    receipt.abonement?.parking.name,
                    receipt.abonement?.plateNumber,
                    receipt.abonement?.begin,
                    receipt.abonement?.end,
                    receipt.abonement?.price,
                    currencySymbols[receipt.abonement?.parking.currency]
                );
            }).catch(() => {
                // navigate('/parking/night-pass/error');
            }).finally(() => setIsLoading(false));

            setParking(parking);
            setEmail(email);
            setPlateNumber(plateNumber);
        }
        initialLoad.current = false;
    }, []);

    return (
        <div>
            <FormContainer withApps={true} withAppDescription={true}>
                <div className="relative flex flex-col items-center">
                    <img src={Success} alt="Error" className="mb-6"/>
                    <div className="flex flex-col space-y-1 mb-6">
                        <p className="text-2xl text-center text-surface font-medium">{t('payment_was_successful')}</p>
                        <p className="text-center text-base text-textNormal">{t('we_have_sent_receipt_to_email')}
                            <br/> {email}
                        </p>
                    </div>
                    <div className="flex flex-col space-y-2 w-full mb-6">
                        <p className="text-textLoud font-bold">{receipt?.typeDefinition}</p>
                        <p className="text-textLoud">{t('night_pass_time')}</p>
                        <p className="text-textNormal text-sm">{t('night_pass_description')}</p>
                    </div>
                    {parking && (
                        <div className="w-full mb-6">
                            <p className="text-textMuted">{receipt?.parking.name}</p>
                            <p className="text-textNormal text-sm">{receipt?.parking?.address}</p>
                        </div>
                    )}
                    {
                        receipt && (
                            <>
                                <div className="flex flex-col space-y-2 w-full mb-4">
                                    <div className="flex justify-between items-center">
                                        <p className="text-textNormal text-sm">{t('car_number')}</p>
                                        <p className="text-textMuted text-sm">{receipt?.plateNumber}</p>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <p className="text-textNormal text-sm">{t('start_date')}</p>
                                        <p className="text-textMuted text-sm">{formatDate(receipt?.begin)}</p>
                                    </div>
                                    <div className="flex justify-between items-center">
                                        <p className="text-textNormal text-sm">{t('end_date')}</p>
                                        <p className="text-textMuted text-sm">{formatDate(receipt?.end)}</p>
                                    </div>
                                </div>
                                <div className="w-full flex justify-between mb-6">
                                    <p className="text-lg">{t('total_cost')}</p>
                                    <p className="text-lg">{receipt?.price} {currencySymbols[receipt?.parking?.currency]}</p>
                                </div>
                            </>
                        )
                    }

                    <Button text={t("close")} styles="bg-[#F2F4F7] hover:bg-disabled" textStyles="text-link"
                            onClick={() => navigate('/parking/night-pass')}/>
                </div>
            </FormContainer>
        </div>
    );
};

export default NightPassSuccess;