import React from 'react';
import {useTranslation} from "react-i18next";

const Rights = () => {
    const { t } = useTranslation();

    return (
        <p className="text-textNormal text-sm text-center">{t('all_rights_reserved')}</p>
    );
};

export default Rights;