import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import errorImg from "@Images/error-img.png";
import FormContainer from "@UI/kit/FormContainer/FormContainer";
import Button from "@UI/kit/Button/Button";

const PaymentErrorForm = () => {
    const {t} = useTranslation();
    let navigate = useNavigate();
    const isUae = localStorage.getItem('isUae') === 'true';

    const onNavigate = () => {
        isUae && localStorage.removeItem('isUae');

        if (window.location.href.includes('t.parqour.com') || window.location.href.includes('q.parqour.com')) {
            navigate(
                `/${localStorage.getItem("parkingUid")}`
            );
        } else {
            navigate(
                `${isUae ? '/uae' : ''}/parking/${localStorage.getItem(
                    "parkingUid"
                )}/${localStorage.getItem("paymentMethod")}`
            );
        }
    }

    return (
        <FormContainer>
            <div className="flex justify-center">
                <img src={errorImg} className="ml-8" alt="errorImg" />
            </div>
            <div className="flex flex-col justify-between items-center mb-2.5">
                <span className="infoCar_name">{t('failure')}</span>
                <span className="infoCar_address fn-16px">{t('try_again')}</span>
            </div>

            <div className="flex flex-col space-y-3">
                <Button
                    onClick={() => {
                        onNavigate();
                    }}
                    text={t('try_again')}
                />
                {/*<button*/}
                {/*    className="btn bg-blur"*/}
                {/*    style={{color: "#157FFF", fontWeight: "600"}}*/}
                {/*    onClick={() => {*/}
                {/*        navigate(`/parking/${localStorage.getItem("parkingUid")}/payment`, {*/}
                {/*            state: {*/}
                {/*                paymentInfo: {*/}
                {/*                    sum: localStorage.getItem("sum"),*/}
                {/*                    plateNumber: localStorage.getItem("plateNumber"),*/}
                {/*                    currency: localStorage.getItem("currency"),*/}
                {/*                },*/}
                {/*            },*/}
                {/*        });*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {t('change_payment_method')}*/}
                {/*</button>*/}

                {/*<button*/}
                {/*    className="btn bg-blur"*/}
                {/*    style={{color: "red", fontWeight: "600"}}*/}
                {/*    onClick={() => {*/}
                {/*        if (window.location.href.includes('t.parqour.com') || window.location.href.includes('q.parqour.com')) {*/}
                {/*            navigate(*/}
                {/*                `${localStorage.getItem("parkingUid")}`*/}
                {/*            );*/}
                {/*        } else {*/}
                {/*            navigate(*/}
                {/*                `${isUae ? '/uae' : ''}/parking/${localStorage.getItem(*/}
                {/*                    "parkingUid"*/}
                {/*                )}/${localStorage.getItem("paymentMethod")}`*/}
                {/*            );*/}
                {/*        }*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {t('cancel')}*/}
                {/*</button>*/}
            </div>
        </FormContainer>
    );
};

export default PaymentErrorForm;
