import React, {useEffect} from "react";
import {BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import {BrowserView, MobileView} from "react-device-detect";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "@/App.css";

import EnterCarForm from "@Pages/payment/EnterCarForm/EnterCarForm";
import ErrorPage from "@Pages/payment/ErrorPage/ErrorPage";
import InfoCar from "@Pages/payment/InfoCar/InfoCar";
import PaymentForm from "@Pages/payment/PaymentForm/PaymentForm";
import SuccessPage from "@Pages/payment/SuccessPage/SuccessPage";
import Payment from "@Pages/payment/InfoCar/Payment/Payment";
import NightPass from "@Pages/payment/NightPass/NightPass";
import NightPassAbonements from "@Pages/payment/NightPass/NightPassAbonements/NightPassAbonements";
import NightPassPayment from "@Pages/payment/NightPass/NightPassPayment/NightPassPayment";

import {ModalProvider} from "@Contexts/ModalContext";
import rightAngleImage from "@Vectors/RightAngle.png";
import leftGroupImage from "@Images/leftGroup.svg";
import Modal from '@UI/kit/Modal/Modal'
import NightPassSuccess from "./pages/payment/NightPass/NightPassSuccess/NightPassSuccess";
import NightPassError from "./pages/payment/NightPass/NightPassError/NightPassError";
import PaymentSubscription from "./pages/payment/InfoCar/Payment/PaymentSubscription";
import {NetworkProvider} from "./contexts/NetworkContext";

const DesktopView = () => (
    <BrowserView>
        <img src={rightAngleImage} className="rightGroup" alt="rightGroup"/>
        <picture>
            <source
                srcSet={leftGroupImage}
                className="leftGroup"
                media="(min-width: 768px)"
            />
            <img src={leftGroupImage} className="leftGroup" alt="leftGroup"/>
        </picture>
    </BrowserView>
);

const MobileViewCustom = () => (
    <MobileView>
        <img src={rightAngleImage} className="rightGroup" alt="rightGroup"/>
        <img src={leftGroupImage} className="leftGroup" alt="leftGroup"/>
    </MobileView>
);

const AppRoutes = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (window.location.pathname === "/login") {
            window.location.href = process.env.REACT_APP_PROMO_BASE_URL;
        }
    }, [navigate]);

    return (
        <Routes>
            <Route path="/parking/:parkingUid/payment" element={<PaymentForm />} />
            <Route path="/:parkingUid/" element={<EnterCarForm />} />
            <Route path="/:parkingUid/:ticket" element={<EnterCarForm />} />
            <Route path="/parking/:parkingUid/:paymentMethod" element={<EnterCarForm />} />
            <Route path="/uae/parking/:parkingUid/:paymentMethod" element={<EnterCarForm />} />
            <Route
                path="/parking/:parkingUid/:paymentMethod/:id/:promo"
                element={<EnterCarForm />}
            />
            <Route path="/parking/:parkingUid/info" element={<InfoCar qr={false} />} />
            <Route path="/parking/:parkingUid/info/payment" element={<Payment qr={false} />} />
            <Route path="/parking/:parkingUid/info/subscription/payment" element={<PaymentSubscription />} />
            <Route path="/parking/" element={<ErrorPage />} />
            <Route path="/payment-error" element={<ErrorPage />} />
            <Route path="/payment-success/:parkingUid/:plateNumber" element={<SuccessPage />} />
            <Route path="/parking/night-pass" element={<NightPass />} />
            <Route path="/parking/night-pass/abonements" element={<NightPassAbonements />} />
            <Route path="/parking/night-pass/payment" element={<NightPassPayment />} />
            <Route path="/parking/night-pass/success/:receiptId" element={<NightPassSuccess />} />
            <Route path="/parking/night-pass/error" element={<NightPassError />} />
        </Routes>
    );
};

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ModalProvider>
                <BrowserRouter>
                    <NetworkProvider>
                    <DesktopView/>
                    <MobileViewCustom/>
                    <Modal/>
                    <AppRoutes/>
                    <ToastContainer/>
                    </NetworkProvider>
                </BrowserRouter>
            </ModalProvider>
        </LocalizationProvider>
    );
}

export default App;