import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import styles from "./uaeCarForm.module.scss";

import {setCode, setRegion, setReverse} from "@Features/uaeCarFormSlice";
import {useIsFirstRender} from "@Hooks/useIsFirstRender";
import {
    abuDhabiCodes,
    ajmanCodes,
    dubaiCodes,
    emiratesArray,
    fujarahCodes,
    kuwaitCodes,
    rasAlCodes,
    sharjahCodes,
    ummAlQuwainCodes,
} from "@Static/static";
import {ErrorType} from "@Enums/errorTypes";

const codesMap = {
    Dubai: dubaiCodes,
    "Abu Dhabi": abuDhabiCodes,
    Sharjah: sharjahCodes,
    Ajman: ajmanCodes,
    "Umm Al Quwain": ummAlQuwainCodes,
    "Ras Al Khaima": rasAlCodes,
    Fujairah: fujarahCodes,
    Kuwait: kuwaitCodes,
};
const noRegion = {
    "Other region": 1,
    Qatar: 1,
    Bahrain: 1,
};
const noCodes = {
    Oman: 1,
    "Saudi Arabia": 1,
};
const isReverse = {
    Oman: true,
    "Saudi Arabia": true,
};

const UaeCarForm = ({plateNumber, error, handlePlateNumber}) => {
    const dispatch = useDispatch();
    const {region, code, reverse} = useSelector((state) => state.uaeCarForm);
    const [isCodeFocused, setIsCodeFocused] = useState(noCodes[region] !== 1);
    const [isPlateNumberFocused, setIsPlateNumberFocused] = useState(false);
    const isFirstRender = useIsFirstRender();
    const {t} = useTranslation();

    useEffect(() => {
        if (!isFirstRender) {
            localStorage.setItem("region", region);
            localStorage.setItem("code", code);
            localStorage.setItem("isReversed", reverse ? "true" : "false");
        }
    }, [region, code, reverse, isFirstRender]);

    useEffect(() => {
        const storedRegion = localStorage.getItem("region");
        const storedCode = localStorage.getItem("code");
        const isReversed = localStorage.getItem("isReversed") === "true";

        if (storedRegion) {
            dispatch(setRegion(storedRegion));
        }
        if (storedCode) {
            dispatch(setCode(storedCode));
        }
        dispatch(setReverse(isReversed));
    }, [dispatch]);
    const handleRegionChange = (e) => {
        const newRegion = e.target.value;
        dispatch(setRegion(newRegion));

        dispatch(setReverse(isReverse[newRegion] ?? false));
        setIsCodeFocused(noCodes[newRegion] !== 1);
    };
    const handleCodeChange = (e) => {
        dispatch(setCode(e.target.value));
    };
    const handleCodeFocus = () => {
        setIsCodeFocused(true);
    };

    const handleCodeBlur = () => {
        setIsCodeFocused(false);
    };

    const handlePlateNumberFocus = () => {
        setIsPlateNumberFocused(true);
    };

    const handlePlateNumberBlur = () => {
        setIsPlateNumberFocused(false);
    };

    return (
        <div className={styles["uae-enter-car-form"]}>
            <div className={styles["select-region-container"]}>
                <span>{t("select_vehicle_region")}</span>
                <select
                    className={styles["select-region"]}
                    onChange={handleRegionChange}
                    value={region}
                >
                    {emiratesArray.map((reg) => (
                        <option value={reg} key={reg}>
                            {reg}
                        </option>
                    ))}
                </select>
            </div>
            {noRegion[region] !== 1 ? (
                <div
                    className={clsx(
                        styles['select-code-container'],
                        (reverse || localStorage.getItem("isReversed") === "true") &&
                        styles['reverse']
                    )}
                >
                    <div className={clsx(styles['input-wrapper'], styles['plate-code'])}>
                        {noCodes[region] !== 1 ? (
                            <select
                                id="vehicle-number-select"
                                onChange={handleCodeChange}
                                value={code}
                                style={{width: "100%", paddingBottom: "3px"}}
                            >
                                {codesMap[region].map((code) => (
                                    <option value={code} key={code}>
                                        {code === "" ? "No plate" : code}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <input
                                placeholder={isCodeFocused ? "" : t("plate_code")}
                                style={{padding: "18px 16px"}}
                                type="text"
                                onChange={handleCodeChange}
                                onBlur={handleCodeBlur}
                                onFocus={handleCodeFocus}
                                value={code}
                                // className={`${error.type === ErrorType.USER_BASED  && "red"}`}
                            />
                        )}
                        {isCodeFocused && (
                            <label htmlFor="input-field">
                                {t("plate_code")}
                            </label>
                        )}
                    </div>
                    <div className={clsx(styles['input-wrapper'], styles['uae-plate-number'])}>
                        <input
                            type="text"
                            placeholder={isPlateNumberFocused ? "" : t("plate_number")}
                            className={`${error.type === ErrorType.USER_BASED && "red"}`}
                            onBlur={handlePlateNumberBlur}
                            onFocus={handlePlateNumberFocus}
                            value={plateNumber}
                            onChange={handlePlateNumber}
                            style={{
                                border:
                                    error.type === ErrorType.USER_BASED ? "1px solid red" : "",
                            }}
                        />
                        {isPlateNumberFocused && (
                            <label htmlFor="input-field" style={{top: "13px"}}>
                                {t("plate_number")}
                            </label>
                        )}
                    </div>
                    {error.type === ErrorType.USER_BASED && error.msg}
                </div>
            ) : (
                <div className={clsx(styles['input-wrapper'], styles['uae-plate-number'])}>
                    <input
                        type="text"
                        value={plateNumber}
                        onBlur={handlePlateNumberBlur}
                        onFocus={handlePlateNumberFocus}
                        placeholder={isPlateNumberFocused ? "" : t("plate_number")}
                        onChange={handlePlateNumber}
                        style={{padding: "18px 16px"}}
                        className={`${error.type === ErrorType.USER_BASED && "red"}`}
                    />
                    {error.type === ErrorType.USER_BASED && error.msg}
                    {isPlateNumberFocused && (
                        <label htmlFor="input-field" style={{top: "13px"}}>
                            {t("plate_number")}
                        </label>
                    )}
                </div>
            )}
        </div>
    );
};

export default UaeCarForm;
