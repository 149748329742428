import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import {getPromocodeTranslations} from "@API/promocode";

const transformTranslations = (data, lang) => {
    const translations = {};
    data?.translationList.forEach((item) => {
        translations[item.name] = item.value[lang];
    });
    return translations;
};

const customBackend = {
    type: "backend",
    init(services, backendOptions, i18nextOptions) {
        // Инициализация бэкенда, если необходимо
    },
    read(language, namespace, callback) {
        getPromocodeTranslations()
            .then((response) => {
                const translations = transformTranslations(response, language);
                callback(null, translations);
            })
            .catch((error) => {
                console.error("Error fetching translations:", error);
                callback(error, false);
            });
    },
    create(languages, namespace, key, fallbackValue) {
        // Этот метод можно оставить пустым, если не нужно добавлять новые переводы
    },
};

i18n
    .use(initReactI18next)
    .use(customBackend)
    .init({
        lng: "en",
        fallbackLng: "en",
        debug: true,
        ns: ["common"],
        defaultNS: "common",
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
