 import i18n from "../i18n/i18n";

export const calculateHours = (amount) => {
  if (amount < 24) {
    return `${amount} ${
      amount !== 1
        ? i18n.t("hours").toLowerCase()
        : i18n.t("hour").toLocaleLowerCase()
    }`;
  } else if (amount >= 24 && amount < 730) {
    // Less than 1 month
    const days = Math.floor(amount / 24);
    return `${days} ${days !== 1 ? i18n.t("days") : i18n.t("day")}`;
  } else if (amount >= 730 && amount < 8760) {
    // Less than 1 year
    const months = Math.floor(amount / 730); // Approximate number of hours in a month
    return `${months} ${
      months !== 1
        ? i18n.t("months").toLocaleLowerCase()
        : i18n.t("month").toLocaleLowerCase()
    }`;
  } else {
    // 1 year or more0
    const years = Math.floor(amount / 8760); // Number of hours in a year
    return `${years} ${years !== 1 ? i18n.t("years") : i18n.t("year")}}`;
  }
};

export const calculateMinutes = (amount) => {
  if (amount < 60) {
    return `${amount} ${i18n.t("min")}`;
  } else if (amount >= 60 && amount < 1440) {
    const hours = amount / 60;
    return `${hours} ${i18n.t("h")}`;
  } else {
    const days = amount / 60 / 24;
    return `${days} ${days === 1 ? i18n.t("day") : i18n.t("days")}`;
  }
};

export const formatDateToYYYYMMDDHHmm = (dateString) => {
  let formattedDate;
  if (typeof dateString !== "string") {
    // coming from local created new Date instance
    let newFormattedDate = formatToApiDate(dateString);
    formattedDate = new Date(newFormattedDate);
  } else {
    if (dateString === "") return "";

    // Convert the input date to a Date object
    const inputDate = new Date(dateString);
    // Create a new Date object representing the same moment in time in the local timezone
    formattedDate = new Date(
      inputDate.getTime() - inputDate.getTimezoneOffset() * 60000
    );
  }
  const year = formattedDate.getFullYear();
  const monthNames = [
    i18n.t("january"),
    i18n.t("february"),
    i18n.t("march"),
    i18n.t("april"),
    i18n.t("may"),
    i18n.t("june"),
    i18n.t("july"),
    i18n.t("august"),
    i18n.t("september"),
    i18n.t("october"),
    i18n.t("november"),
    i18n.t("december"),
  ];
  const month = monthNames[formattedDate.getMonth()]; // getMonth() is zero-based
  const day = String(formattedDate.getDate()).padStart(2, "0");

  // Convert 24h to 12h format
  let hours = formattedDate.getHours();
  const amPm = hours >= 12 ? "p.m" : "a.m";
  hours = hours % 12;
  hours = hours || 12; // Convert '0' to '12' for 12 AM
  const formattedHours = String(hours).padStart(2, "0");
  const minutes = String(formattedDate.getMinutes()).padStart(2, "0");

  return `${day} ${month} ${year} ${formattedHours}:${minutes} ${amPm}`;
};
export const formatToApiDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
};

export const formatCheckInDate = (inDate) => {
  const inputDate = new Date(inDate);
  const now = new Date();
  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "p.m." : "a.m.";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  const formatDateComponents = (date) => {
    const day = date.getDate();
    const months = [
      i18n.t("january"),
      i18n.t("february"),
      i18n.t("march"),
      i18n.t("april"),
      i18n.t("may"),
      i18n.t("june"),
      i18n.t("july"),
      i18n.t("august"),
      i18n.t("september"),
      i18n.t("october"),
      i18n.t("november"),
      i18n.t("december"),
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  if (inputDate.toDateString() === now.toDateString()) {
    return formatAMPM(inputDate);
  }

  return `${formatDateComponents(inputDate)} ${formatAMPM(inputDate)}`;
};

export const formatDateBasedOnToday = (
  dateString,
  freeMinutes = false,
  finalDate = ""
) => {
  const date = new Date(finalDate ? finalDate : dateString);

  if (freeMinutes) {
    date.setMinutes(date.getMinutes() + 10);
  }

  const now = new Date();
  const timeZoneOffset = dateString.match(/([+-]\d{2}:\d{2})$/)[0];

  // Check if the date is today
  const isToday = date.toDateString() === now.toDateString();

  // Define options for formatting based on whether the date is today
  let options;
  if (isToday) {
    options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
  } else {
    options = {
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
  }

  return new Intl.DateTimeFormat("en-US", {
    ...options,
    timeZone: timeZoneOffset,
  }).format(date);
};

export const calculateStayDuration = (inDate) => {
  if (!inDate) return i18n.t("no_data");

  const inDateTime = new Date(inDate);
  const now = new Date();
  const diffInMs = now - inDateTime;

  // Convert milliseconds to minutes, hours, and days
  const minutes = Math.floor(diffInMs / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} ${i18n.t("days")} ${hours % 24} ${i18n.t("hours")} ${
      minutes % 60
    } ${i18n.t("minutes")}`;
  } else if (hours > 0) {
    return `${hours} ${i18n.t("hours")} ${minutes % 60} ${i18n.t("minutes")}`;
  } else {
    return `${minutes} ${i18n.t("minutes")}`;
  }
};

export const decodeJWT = (token) => {
    try {
      const base64Url = token?.split(".")[1];
      if (!base64Url) throw new Error("Invalid token");
  
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error("Failed to decode JWT:", e);
      return null;
    }
  };

export const mergeActiveMergetList = (activeMergetList) => {
  const merged = {};

  activeMergetList.forEach((item) => {
    const key = `${item.type}-${item.expirationHours}-${item.value}-${item.limitValue}`;

    if (!merged[key]) {
      merged[key] = { ...item, id: [item.id], activeCount: item.activeCount };
    } else {
      merged[key].activeCount += item.activeCount;
      merged[key].id.push(item.id);
    }
  });
  return Object.values(merged);
};

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

 export function declensionOfDays(number, locale) {
   const lastDigit = number % 10;
   const lastTwoDigits = number % 100;

   switch (locale) {
     case 'en':
       if (number === 1) {
         return `${number} day`;
       } else {
         return `${number} days`;
       }
     case 'ru':
       if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
         return `${number} дней`;
       }

       if (lastDigit === 1) {
         return `${number} день`;
       } else if (lastDigit >= 2 && lastDigit <= 4) {
         return `${number} дня`;
       } else {
         return `${number} дней`;
       }
     case 'kk': // Kazakh always uses "күн"
       return `${number} күн`;
     case 'az':
       if (number === 1) {
         return `${number} gün`;
       } else {
         return `${number} gün`;
       }
     default:
       return `${number} days`;
   }
 }

 export const getFreeTimeBeforeExit = (minutes) => {
   const currentDate = new Date();
   currentDate.setMinutes(currentDate.getMinutes() + minutes);

   let hours = currentDate.getHours();
   const minutesPart = String(currentDate.getMinutes()).padStart(2, '0');
   const period = hours >= 12 ? 'p.m' : 'a.m';

   hours = hours % 12 || 12;

   const formattedTime = `${String(hours).padStart(2, '0')}:${minutesPart} ${period}`;

   return `${formattedTime}`;
 };

