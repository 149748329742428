import {useState, useEffect, useCallback} from 'react';
import {getParkingByUid} from '@API/axiosRequest';

const useParkingInfo = (parkingUid) => {
    const [parkingInfo, setParkingInfo] = useState(null);

    const fetchParkingInfo = useCallback(async () => {
        try {
            const response = await getParkingByUid(parkingUid);
            setParkingInfo(response?.message);
        } catch (error) {
            console.error('Failed to fetch parking info:', error);
        }
    }, [parkingUid])

    useEffect(() => {
        if (parkingUid) {
            fetchParkingInfo();
        }
    }, [parkingUid, fetchParkingInfo]);

    return {parkingInfo, fetchParkingInfo};
};

export default useParkingInfo;
